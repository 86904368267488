import {React, useEffect, useState} from 'react'
import Cookies from 'js-cookie';

const currentHouseholdId = Cookies.get('currentHouseholdId');

function Notes({api, notes, fetchNotes, userIdAndFullName}) {


    useEffect(() => {
       

        fetchNotes(api);
      }, []);

    
    const deleteNotes = async(notes_id) => { 
      const reponse = await fetch(`${api}/api/notes?notes_id=${notes_id}`, { 
        method : "DELETE", 
      })
      if(reponse.ok){ 
        fetchNotes(api);
      }else { 
        alert("cannot delete this note!");
      }
    }



  return (
    
    <div className='overflow-wrapper'>
      
      
        {notes ? notes.map(note => (
        <div className='margin-bottom-10 padding-bottom-5px border-bottom'>
        <p> {userIdAndFullName[note.user_id]}</p>
        <p>{new Date(note.date_created).toLocaleString('en-US')}</p>
        <p>*{note.description}</p>
        <p className = "hover-cursor-pointer color-red" onClick={() => deleteNotes(note.notes_id)} >🗑️Delete</p>

        
        </div>
        )): null}
    
    </div>
  )
}

export default Notes

import React, {useRef} from 'react'

function Change_ownership({setCurrentPopup,  userIdAndFullName, checkedHouseholds, api, fetchData, currentPage, toDate, fromDate, searchTerm}) {
    const user_id = useRef(null)

    const postStatus = async() =>{
        const arrOfHouseholds = [...checkedHouseholds];
        const response = await fetch(`${api}/api/household/change_owner`, { 
             method: "POST", 
             headers: { 
                "Content-Type": "application/json"
             }, 
             body: JSON.stringify({
                userID: user_id.current.value, 
                checkedHouseholds: arrOfHouseholds 
              }) 
        })
        return response;
    }

    const changeStatus = async() => { 
        const response = await postStatus();
        if(response.status === 200){ 
             fetchData(currentPage, searchTerm, toDate, fromDate);
             return setCurrentPopup(null)
        }
        alert('Error changing ownership!')
    }

  return (
   <>
    <div className='background-color-full-dark border-radius-10px  popup z-index'  >
         <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= { () => setCurrentPopup(null)}>❌</button></div>
             <div className='flex-container-el'> 
                <h3 className='padding-10px'>Assign to user </h3>
             <select className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' ref = {user_id} >
                { userIdAndFullName && Object.keys(userIdAndFullName).map(user => (
                    <option value = {user}>{userIdAndFullName[user]}</option>
                ))}
             </select>
             <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick = {() => changeStatus()} >Change Status</button>
             
         </div>
     </div>
   </>
  )
}

export default Change_ownership

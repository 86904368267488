import React, { useState, useEffect} from 'react';
import Cookies from 'js-cookie'
const { getFollowUps } = require('./fetches/fetch');



function FollowUps({currentUserData}) {

const [followUps, setFollowUps] = useState();


useEffect(()=> { 
 async function init_followUps() { 
  if(currentUserData[0].role === "Admin"){ 
    const response = await  getFollowUps();
    const data = await response.json();
    setFollowUps(data);
  } else { 
    console.log(currentUserData[0])
    const response = await  getFollowUps(currentUserData[0].id);
    const data = await response.json();
    setFollowUps(data);
  }
 }
 init_followUps();
}, []) 

  return (
    <>
    {console.log(followUps)}
    {followUps? ( 
    <div className = "flex-container-el max-width light-dark-container max-height ">
    <h2 className='light-dark-container'>Follow Ups  </h2>
    <div class= "text-align-center  followups-table  ">
          <table >
            <thead>
              <th >Driver Name</th>
              <th>Status</th>
              <th>Last Updated</th>
            </thead>
            <tbody className = "no-borders follow-up-height   " id="search-results_table_body"> 
              {
              followUps && followUps.map(driver => ( 
                <tr onClick = { () => {Cookies.set('currentHouseholdId', driver.household_id[0]); window.location.reload()}}>
                  <td>
                    {driver.firstname}
                    <br/>
                    {driver.lastname}
                  </td>
                  <td>{driver.status_description}</td>
                  <td>{driver.updated_at ? new Date(driver.updated_at).toLocaleDateString() : new Date(driver.date_created[0]).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
      
      </div>
    </div>
    ) : <div className = "flex-center-column light-dark-container"><img   className = "light-dark-container ten-percent-width" src = "https://i.gifer.com/ZKZg.gif"/></div>}
    </>
  )
}

export default FollowUps

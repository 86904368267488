import React, { useEffect, useState, useRef} from 'react';
import Leads_popup from './leads_page_popups/Leads_popup';
import Change_status from './leads_page_popups/Change_status';
import SearchResults from './SearchResults';
import Cookies from 'js-cookie';
import Change_ownership from './leads_page_popups/Change_ownership';
import Change_tags from './leads_page_popups/Change_tags';
import Change_vendors from './leads_page_popups/Change_vendors';
import Delete_lead from './leads_page_popups/Delete_lead';
import Transfer_tasks from './leads_page_popups/Transfer_tasks';
import Merge_records from './leads_page_popups/Merge_records';
import Change_Branch from './leads_page_popups/Change_Branch';
import Add_story from './leads_page_popups/Add_story';
const { getCount} = require('./fetches/fetch')

const jwt = Cookies.get('jwt');

function Searchleads({ currentPage, setCurrentPage, currentPopup, setCurrentPopup, api, searchTerm, setCurrentHouseholdId, currentUserData, userIdAndFullName, setSearchTerm, setSwitchNumber }) {
  const [checkedHouseholds, setCheckedHouseholds] = useState(new Set());
  const [driverData, setDriverData] = useState(undefined);
  const [status_description, setStatus_Description] = useState("");
  const [owner, setOwner] = useState("");
  const [driversThatBelongToUser, setDriversThatBelongToUser] = useState()
  const [searchType, setSearchType] = useState('full_name');
  const [fromDate, setFromDate] = useState(undefined)
  const [toDate, setToDate] = useState(undefined)
  const [numOfPage, setNumOfPage] = useState();

  const fromDateValue = useRef(null);
  const toDateValue = useRef(null);
  const searchTypeValue = useRef(null);
  const currentSelect = useRef("");
  const currentOwner = useRef("");


  useEffect( () => {
    const async = async() => {
      try{ 
        calculateNumOfLeads();
      }catch(error){ 
        return
      }
      

      
    }
    async();
   
    fetchData(currentPage, searchTerm, fromDate, toDate);
  }, [searchTerm]);

  const nextPage = () => { 
    let nextPageTemp = currentPage + 1;
    setCurrentPage(nextPageTemp);
    fetchData(nextPageTemp, searchTerm, fromDate, toDate);
   
  }

  const previousPage = () => { 
    if (currentPage > 1) { 
      let previousPageTemp = currentPage - 1;
      setCurrentPage(previousPageTemp);
      fetchData(previousPageTemp, searchTerm, fromDate, toDate);
      
    } else { 
      alert("No previous search results");
    }
  }

  
  const calculateNumOfLeads = async() => { 
    const response = await getCount(); 
    if(response.status === 500){
      return;
     }
     const data = await response.json();
     setNumOfPage(data.num)

   
  }
  


  const fetchData = async (currentPage, searchTerm, fromDate, toDate) => {
    
    let endpoint = `${api}/api/drivers/all?page=${currentPage}`;

    //Agents only can see their leads
    if(currentUserData[0].role === "Agent"){ 
      endpoint += `&user_id=${Cookies.get('userid')}`
    }
    if(currentUserData[0].role === "Admin"){ 
      if(currentSelect.current.value !== "" ){ 
        
        endpoint +=`&status_description=${currentSelect.current.value}`
      }
      if(currentOwner.current.value !== ""){ 

        endpoint +=`&owner=${currentOwner.current.value}`
      }
    }
    //to add search term to api endpoint request
    if(searchTerm){ 
      
      endpoint += `&searchTerm=${searchTerm}`;
    }

  
    //to add from and to date to api endpoint request
    if(fromDate && toDate ){ 
      setToDate(toDateValue.current.value);
      setFromDate(fromDateValue.current.value);
      endpoint +=`&fromDate=${fromDate}&toDate=${toDate}`;
    }
    if(searchType){ 
      
      endpoint +=`&searchType=${searchType}`;
    }

      try {
        const response = await fetch(endpoint, { 
          headers: { 
            Authorization: jwt
          }
        });
        const data = await response.json();
             setDriverData(data);
      } catch (error) {
        console.error(error);
      }
    } 
  

const clearSearch = () => { 
  toDateValue.current.value = "";
  fromDateValue.current.value = "";
  setSearchTerm(undefined)
  setSearchType('full_name');
  fetchData(currentPage, undefined, undefined, undefined);
}

const popups = [
  <Leads_popup  setCurrentPopup = {setCurrentPopup}  checkedHouseholds ={ checkedHouseholds} />, 
  <Change_status  setCurrentPopup = {setCurrentPopup} checkedHouseholds = {checkedHouseholds} api={api} fetchData={fetchData} currentPage={currentPage} toDate={toDate} fromDate={fromDate} searchTerm={searchTerm} />,
  <Change_ownership setCurrentPopup = {setCurrentPopup} checkedHouseholds = {checkedHouseholds} api={api} fetchData={fetchData} currentPage={currentPage} toDate={toDate} fromDate={fromDate} searchTerm={searchTerm} 
  userIdAndFullName = {userIdAndFullName} />, 
  <Change_tags setCurrentPopup = {setCurrentPopup} checkedHouseholds = {checkedHouseholds} api={api} fetchData={fetchData} currentPage={currentPage} toDate={toDate} fromDate={fromDate} searchTerm={searchTerm} />,
  <Change_vendors setCurrentPopup = {setCurrentPopup} checkedHouseholds = {checkedHouseholds} api={api} fetchData={fetchData} currentPage={currentPage} toDate={toDate} fromDate={fromDate} searchTerm={searchTerm} />, 
  <Delete_lead setCurrentPopup = {setCurrentPopup} checkedHouseholds = {checkedHouseholds} api={api} fetchData={fetchData} currentPage={currentPage} toDate={toDate} fromDate={fromDate} searchTerm={searchTerm} />, 
  <Transfer_tasks setCurrentPopup = {setCurrentPopup} checkedHouseholds = {checkedHouseholds} api={api} fetchData={fetchData} currentPage={currentPage} toDate={toDate} fromDate={fromDate} searchTerm={searchTerm}
  userIdAndFullName = {userIdAndFullName}/>, 
  <Merge_records setCurrentPopup = {setCurrentPopup} checkedHouseholds = {checkedHouseholds} api={api} fetchData={fetchData} currentPage={currentPage} toDate={toDate} fromDate={fromDate} searchTerm={searchTerm}
  userIdAndFullName = {userIdAndFullName} />, 
  <Change_Branch setCurrentPopup = {setCurrentPopup} checkedHouseholds = {checkedHouseholds} api={api} fetchData={fetchData} />, 
  
]



  return (
    <>


<div className='flex-across-space-between light-dark-background border-radius-10px'> 
      <div className='light-dark-background border-radius-10px padding-10px'>
        <button type ="button" className='light-dark-background button-default green-submit-btn' onClick={()=> fetchData(currentPage, searchTerm,fromDateValue.current.value, toDateValue.current.value )}>Filter</button>
        <p className='inline-el light-dark-background margin-left-10'>From:</p>
        <input type ='date' className='margin-left-10' id = "from-date" ref ={fromDateValue}/> 
        <p className='inline-el light-dark-background margin-left-10'>To:</p>
        <input type ='date' className='margin-left-10' id = "to-date" ref ={toDateValue}/> 
        <select className=' padding-10px ' onClick = {()=> { clearSearch(); setSearchType(searchTypeValue.current.value)}} ref = {searchTypeValue} > 
            <option value = "firstname">Firstname</option>
            <option value = "lastname">Lastname</option> 
            <option value = 'home_phone'>Home Phone</option>
            <option value = 'status'> Status</option>
            <option value = 'household_id'> Household ID</option>
            <option value = 'full_name'> Full_name</option>
            <option value = 'policy_id'> Policy ID</option>
            <option value = 'address_line_one'>Address Line One</option>
          </select>
      
        <button type ="button" className='light-dark-background button-default green-submit-btn margin-left-10' onClick={()=> clearSearch() }>Clear Filter</button>
       {currentUserData && currentUserData[0].role === 'Admin' ? <button type = "button" className='light-dark-background button-default green-submit-btn margin-left-10' onClick = { () => setCurrentPopup(0)}>Actions </button> : null } 
      </div>
      
      <div className='light-dark-background border-radius-10px padding-10px'>
        <button type = "button"className='light-dark-background inline-el margin-left-10 button-default' onClick={()=> previousPage()} >Previous</button>
        <p className='inline-el light-dark-background margin-left-10'>{currentPage}</p>
        <button type = "button"className='light-dark-background inline-el margin-left-10 button-default ' onClick={()=> nextPage()} >Next</button>
    
      </div>
    
  </div>
 
   
    <p className='padding-10px'>Search Term: {searchTerm}</p>
    <p className='padding-10px'>Search Type: {searchType}</p>
    <p className='padding-10px' >Pages: {numOfPage ? numOfPage / 2 : null}</p>
    
    {typeof currentPopup === 'number' && popups[currentPopup]}

    
    <SearchResults currentOwner={currentOwner} searchTerm = {searchTerm} currentSelect={currentSelect} setStatus_Description= { setStatus_Description} setOwner = {setOwner} setSearchTerm= {setSearchTerm} currentPage = {currentPage} fetchData= { fetchData} setSearchType = { setSearchType } setCheckedHouseholds = {setCheckedHouseholds}  checkedHouseholds = {checkedHouseholds} driverData = {driverData} setCurrentHouseholdId ={setCurrentHouseholdId} driversThatBelongToUser={driversThatBelongToUser} currentUserData={currentUserData} userIdAndFullName = {userIdAndFullName} setSwitchNumber={setSwitchNumber} />
    </>
  )
}

export default Searchleads

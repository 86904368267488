import React from 'react'

function SelectPolicyTypePopup({setCurrentPopup, setPolicyType}) {
  return (
    <div className='background-color-full-dark border-radius-10px popup  ' id = "select-policy-type-popup" >
<div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)} > ❌</button></div>
<h3 className='text-align-center margin-bottom-10 t'>Select Policy Type</h3> 
  <div className = ' policy-type-control'> 
    <div className='flex-container-el padding-20px '>

    
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(3)}>Automobile</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(2)}>Motorcycle</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(4)}>Jewelry</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(5)}>Home</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(10)}>Rental</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('PUP')}}>PUP</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Health')}}>Health</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('General Liability')}}>General Liability</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Business Owners Package')}}>Business Owners Package</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Disability')}}>Disability</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Life')}}>Life</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Umbrella')}}>Umbrella</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Event')}}>Event</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Comprehensive Personal Liability')}}>Comprehensive Personal Liability</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Flood')}}>Flood</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Recreational Vehicles')}}>Recreational Vehicles</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Mobile Home')}}>Mobile Home</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Landlords')}}>Landlords</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Annuity')}}>Annuity</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Fixed Annuity')}}>Fixed Annuity</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Variable Annuity')}}>Variable Annuity</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Index Annuity')}}>Index Annuity</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Excess Liabiity')}}>Excess Liability</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Errors and Omissions')}}>Errors and Omissions</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Directors and Officers')}}>Directors and Officers</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Crime')}}>Crime</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Surety Bond')}}>Surety Bond</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Miscellaneous')}}>Miscellaneous Bond</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Package')}}>Package</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Property')}}>Property</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Condominium')}}>Condominium</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Inland Marine')}}>Inland Marine</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Installation Builders Risk')}}>Installation Builders Risk</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Boat Owners')}}>Boat Owners</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Property (Dwelling Fire)')}}>Property (Dwelling Fire)</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Property (Off-Road Vehicles)')}}>Off-Road Vehicle</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Small Commercial Package')}}>Small Commercial Package</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Glass')}}>Glass</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Schedule Personal Property')}}>Schedule Personal Property</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Earthquake')}}>Earthquake</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Long-Term-Care')}}>Long-Term Care</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('NSF')}}>NSF</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Mexican Travel')}}>Mexican Travel</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Roadside')}}>Roadside</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Excess and Surplus')}}>Excess and Surplus</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Builders Risk')}}>Buildder's Risk</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Pet Health')}}>Pet Health</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Contract')}}>Contract</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('OTHER')}}>OTHER</button>
      <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => {setCurrentPopup(11); setPolicyType('Windowstorm')}}>Windowstorm</button>
    </div>
  </div>
</div>
  )
}

export default SelectPolicyTypePopup


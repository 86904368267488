import {React, useState, useEffect, useRef} from 'react';
import View_ticket from './View_ticket';
import Knowledge_base from './Knowledge_base';
import Cookies from 'js-cookie';
import './utils.css';


function Tech_support({api, currentUserData}) {

    useEffect(() => { 
        fetchTicket();
    }, [])

    const [activeComponent, SetActiveComponent] = useState(1);
    const [ticketData, setTicketData] = useState();
    const fileUpload = useRef();

    const fetchTicket = async() => { 
        const response = await fetch(`${api}/api/ticket`)
        const data = await response.json();
        setTicketData(data);


    }

    const createTicket = () => { 
        const inputElements = document.querySelectorAll('.ticket-input');
        let ticketInputObj = {
            createdBy : Cookies.get('userid'),
            name: currentUserData[0].full_name,
            email: currentUserData[0].email
        }
        inputElements.forEach(element => { 
            ticketInputObj[element.name] = element.value;
            
        })
        const formData = new FormData();
        formData.append("file", fileUpload.current.files[0]);
        formData.append('ticketInputObj', JSON.stringify({ticketInputObj})); 
        addTicket(formData);
        

    }

    const addTicket = async(inputObj) => { 
        const response = await fetch(`${api}/api/ticket`,{
            method: "POST",
            body: inputObj
        })
        if(!response.ok){ 
            alert('Error adding ticket');
        }else { 
            fetchTicket();
        }
    }

    const togglePopup = (element) => { 
        element.hidden = !element.hidden;


    }

    const arrayOfComponents = [ 
        <View_ticket ticketData = {ticketData} api = {api} fetchTicket={fetchTicket} currentUserData= {currentUserData} />,
        <Knowledge_base/>
    ]
  return (
    
    <div className = "flex">
        
      <div className = "flex flex-direction-column bg-black vh-height-full width-200px" name = "sidebar-left">
        <ul className='bg-black padding-10px-around '>
            <li className='bg-black border-under margin-bottom-10px cursor-pointer' onClick={() => togglePopup(document.getElementById('add-user-popup'))}>Create Ticket</li>
           <li className='bg-black border-under margin-bottom-10px cursor-pointer' onClick={ () => SetActiveComponent(0)} >View Ticket</li>
            <li className='bg-black border-under margin-bottom-10px cursor-pointer '  onClick={ () => SetActiveComponent(1)}>Knowledge Base</li>
        </ul>

      </div>
        {arrayOfComponents[activeComponent]}

        <div className='background-color-full-dark border-radius-10px popup width-500px' id = "add-user-popup" hidden  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={ () => togglePopup(document.getElementById('add-user-popup'))} >❌</button></div>
        <div className='flex-container-el padding-20px'> 
       
        <h3 className='text-align-left margin-bottom-10'>Create Ticket  </h3>
        <label for = "lastname" className='background-color-full-dark text-align-left   margin-top-5px '>Choose File</label>
        <input type = 'file' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width ticket-input' ref = {fileUpload }></input>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Issue Catergory</label>
        <select type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width  ticket-input' name = "issue_category" autocomplete="off">
            <option value = "Software">Software</option>
            <option value = "Hardware">Hardware</option>
            <option value = "Network">Network</option>
            <option value = "Other">Other</option>
        </select>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Last Description</label>
        <textarea className ='ticket-input width-full height-300px border-color-white ticket-input ' name= "issue_description"></textarea>
        <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " id = "add=driver-submit-btn" onClick={ () => {createTicket(); togglePopup(document.getElementById('add-user-popup'))  }} >Create New Ticket</button>
        </div>
      </div>

      
    </div>
  )
}

export default Tech_support

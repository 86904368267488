import {React, useRef} from 'react'
import Cookies from 'js-cookie';

function NewVehicles({setCurrentPopup, driverData, api, getVehicles}) {
    const driver_id = useRef();
    const make = useRef();
    const model = useRef();
    const year = useRef();
    const vin = useRef();


    const CREATE_NEW_OBJ = () => { 
        if(driver_id.current.value && make.current.value && model.current.value && year.current.value && vin.current.value){ 
            let vehicleObj  = { 
                driver_id : driver_id.current.value, 
                make: make.current.value, 
                model: model.current.value, 
                year: year.current.value, 
                vin: vin.current.value
            }
            return vehicleObj
        }else { 
            alert('Missing a input value!');
        }
    }

    const POST_NEW_VEHICLE = async(vehicleObj) => { 
        if(Cookies.get('jwt')){ 
            const response = await fetch(`${api}/api/driver/${vehicleObj.driver_id}/addVehicle`, { 
                method: "POST", 
                headers: { 
                    'Content-Type' : 'application/json', 
                    Authorization: Cookies.get('jwt')
                }, 
                body: JSON.stringify(vehicleObj)
            })
            return response
         }   
        else { 
             window.location.href = '/';
            }
    } 


    const CREATE_NEW_VEHICLE_BTN = async() => { 
        const obj = CREATE_NEW_OBJ();
      
      
        const response = await POST_NEW_VEHICLE(obj);
        if(response.status === 500){ 
            alert('Internal Server Error')
        }else if (response.status === 200){ 
            getVehicles();
            setCurrentPopup(null);
        }
        

    }
  return (
    <div className='background-color-full-dark border-radius-10px popup z-index ' id = "add-vehicle"  >
      <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick = {() => setCurrentPopup(null)}>❌</button></div>
        <div className='flex-container-el padding-20px'> 
        <h3 className='text-align-left'> Add New Vehicle</h3>
        <label for = "firstname" className='background-color-full-dark text-align-left '>Driver Name</label>
        <select className='background-color-full-dark regularTextInput margin-top-5px  max-width' ref = {driver_id} > 
          {driverData && driverData.map(driver => (
            <option value = {driver.drivers_id}>{driver.firstname + " " + driver.lastname}</option>
          ))}
          </select>
          
          <label for = "firstname" className='background-color-full-dark text-align-left  '>Make</label>
          <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off"  ref={make}/>
          <label for = "lastname" className='background-color-full-dark text-align-left margin-top-10px '>Model</label>
          <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off" ref={model}/>
          <label for = "relation" className='background-color-full-dark text-align-left margin-top-10px'>Year</label>
          <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off" ref={year}/>
          <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Vin</label>
          <input type = "text" className='background-color-full-dark regularTextInput margin-top-5px max-width' id = "vin" autocomplete="off" ref={vin}/>
          <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width "  onClick = {() => CREATE_NEW_VEHICLE_BTN()}>Add New Vehicle</button>
        </div>
 </div>
  )
}

export default NewVehicles

import React, { useEffect, useState, useRef } from 'react';
import HouseholdController from './middle_popups/HouseholdController';
import Cookies from 'js-cookie';
import TabSection from './tab-section';
import NewHouseHolds from './middle_popups/NewHouseHolds';
import NewDrivers from './middle_popups/NewDrivers';
import NewVehicles from './middle_popups/NewVehicles';
import QuotePopup from './middle_popups/QuotePopup';
import NewStatus from './middle_popups/NewStatus';
import EditDriver from './middle_popups/EditDriver';
import EditVehicle from './middle_popups/EditVehicle';
import NewTasks from './middle_popups/NewTasks';
import ChangeOrg from './middle_popups/changeOrg';
import TileDisplay from './tileDisplay';
import HouseholdInfo from './middle_popups/HouseholdInfo';
import { useSelector, useDispatch } from 'react-redux'; 
import {property, personal_auto, commercial} from './redux/tileDisplayIndex'
import { postOrganization, getChildOrgs } from './fetches/fetch';
import SelectPrimaryDriver from './middle_popups/SelectPrimaryDriver';
import NewOrganization from './middle_popups/NewOrganization'

function MiddleSide({currentUserData, api, allUserData, setCurrentHouseholdId, currentHouseholdId, userIdAndFullName, getTasks, setRecentCustomers, recentCustomers}) {
  
  const [householdData, setHouseholdData] = useState(null);
  const [driverData, setDriverData] = useState(null);
  const [vehicleData, setVehicleData] = useState([]);
  const [slide, setSlide] = useState(null);
  const [page, setPage] = useState(1);
  const [activeComponentIndex, setActiveComponentIndex] = useState(null);
  const [parentHouseholdId, setParentHouseholdId] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const jwtToken = Cookies.get('jwt');
  
  const [toggleOrg ,setToggleOrg] = useState(false);

  const [currentPopup, setCurrentPopup] = useState(null);
  const tileIndex = useSelector((state) => state.tileDisplayIndex.index);
  const dispatch = useDispatch();

  const getVehicles = () => { 

   
    if (jwtToken && currentHouseholdId) {
      
      fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${jwtToken}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => { 
        
        setRecentCustomers(oldArray => [...oldArray, data[0]]);
        const json_str = JSON.stringify(recentCustomers);
       
        //Json.stringfy returns empty [] so we check to see if the lengh if greeater than 2 characters to see if it is empty. Only set storage if the string is not empty.
        if(localStorage.getItem(recentCustomers) === null && json_str.length > 2){
         localStorage.setItem('recentCustomers', json_str)
        }
     
 
        
        
      
        //Clear the state before loop
        setVehicleData([]);
        Promise.all(data.map(driver => {
          return fetch(`${api}/api/driver/${driver.drivers_id}/vehicles`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${jwtToken}`
            }
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(vehicleData => {
          //Clear prevState 
           
          //Vehicle Data contains an array in an array
            if(vehicleData[0]?.length !== 0 ){ 
              
              //When only one driver has vehicles
            if(vehicleData[0].length >=2){ 
              //Add each object to the state which is an array because when multiple drivers have vehicles the VehicleData is seperate arrays/objs instead of one whole one.
             vehicleData[0].forEach(vehicle => { 
              
                setVehicleData(prevStates => [...prevStates, vehicle]);
              })
              
            }else { 
              //when multiple drivers have vehicles
              setVehicleData(prevStates => [...prevStates,vehicleData[0][0]]);
             
            }
             
            }
            
            // Merge driver and vehicle data
            return { ...driver, vehicle: vehicleData[0] };
          });
        }))
        .then(mergedData => {
          // Update driverData with merged data
          setDriverData(mergedData);
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
      } 
      
    
    )
      .catch(error => {
        // Handle any errors
        console.error('There was a problem with your fetch operation:', error);
      });
    } else {
      console.error('jwtToken or currentHouseholdId is missing');
    }
  }



  const setLatestHousehold = async() => { 
    const response = await fetch(`${api}/api/household/latest?page=1`);
    const data = await response.json();
    Cookies.set('currentHouseholdId',data[0].household_id )
    setCurrentHouseholdId(data[0].household_id);
  
  }

  function formatPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    const formattedPhoneNumber = ` (${cleanedPhoneNumber.substring(0, 3)}) (${cleanedPhoneNumber.substring(3, 6)}) (${cleanedPhoneNumber.substring(6)})`;
    return formattedPhoneNumber;
  }

  



  const updateDrivers = async(currentHouseholdId) => { 
    fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${jwtToken}`
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setDriverData(data);
      
     
    })
    .catch(error => {
      // Handle any errors
      console.error('There was a problem with your fetch operation:', error);
    });
  
  }


    

const togglePopUpByEl = (element) => { 
  element.hidden = !element.hidden;
}


const getHouseholdData = async() => { 
  if (jwtToken && currentHouseholdId) {
    fetch(`${api}/api/household?id=${currentHouseholdId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${jwtToken}`
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      
      setHouseholdData(data[0]);
    })
    .catch(error => {
      // Handle any errors
      console.error('There was a problem with your fetch operation:', error);
    });
  } else {
    console.error('jwtToken or currentHouseholdId is missing');
  }
}



  useEffect(() => {

 
  getHouseholdData();
  }, [jwtToken, currentHouseholdId]);

  useEffect(() => {

    
    if (jwtToken && currentHouseholdId) {
      fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${jwtToken}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setDriverData(data);
        
       
      })
      .catch(error => {
        // Handle any errors
        console.error('There was a problem with your fetch operation:', error);
      });
    } else {
      console.error('jwtToken or currentHouseholdId is missing');
    }
  }, [jwtToken, currentHouseholdId]);


  useEffect(() => {
    
    getVehicles();

    async function async() { 
      const response = await getChildOrgs(Cookies.get('currentHouseholdId'));
      const data = await response.json();
      
      setOrganizations(data);
    }
    async();
    
  },[jwtToken, currentHouseholdId]);

  console.log(organizations)
  const changeHousehold = async(event) => { 
    
 
   
   if(event.target.id === "next-household"){  
    setActiveComponentIndex(null);
    let seeIfPageValid = page + 1;
    
    if(currentUserData[0]?.role === 'Admin' ||currentUserData[0]?.role === 'Supervisor' ){ 
     
    const response = await fetch(`${api}/api/household/latest?page=${seeIfPageValid}`);
    
    const data = await response.json();
    if(data.length !== 0 ){ 
      
      
     
      setCurrentHouseholdId(data[0]?.household_id);
      
     
      setActiveComponentIndex(0)
      Cookies.set('currentHouseholdId',data[0]?.household_id);
      setPage(seeIfPageValid);
      }else { 
        alert('This is your oldest added household!');
      }
     


    }else { 

      let seeIfPageValid = page + 1;
      const response = await fetch(`${api}/api/household/belong?page=${seeIfPageValid}&user_id=${currentUserData[0]?.id}`);
      const data = await response.json();

      if(data.length !== 0 ){ 
      
        setPage(seeIfPageValid)
        
        setCurrentHouseholdId(data[0]?.household_id);

        setActiveComponentIndex(0)
        Cookies.set('currentHouseholdId',data[0]?.household_id);
        }else { 
          alert('No next households!');
        }
       
    }
  }

    if(event.target.id === "previous-household"){  
      setActiveComponentIndex(null);
      if( page > 1){ 
        let seeIfPageValid = page - 1;
      
      if(currentUserData[0].role === 'Admin'){ 
       
      const response = await fetch(`${api}/api/household/latest?page=${seeIfPageValid}`);
      
      const data = await response.json();
      if(data.length !== 0 ){ 
        
        setPage(seeIfPageValid);
        setCurrentHouseholdId(data[0].household_id);
        setActiveComponentIndex(0)
        Cookies.set('currentHouseholdId',data[0].household_id);
        }else { 
          
        }
       
  
  
      }else { 
        const response = await fetch(`${api}/api/household/belong?page=${seeIfPageValid}&user_id=${currentUserData[0]?.id}`);
        const data = await response.json();
  
        if(data.length !== 0 ){ 
        
          setPage(seeIfPageValid);
          setCurrentHouseholdId(data[0]?.household_id);
          setActiveComponentIndex(0)
          Cookies.set('currentHouseholdId',data[0].household_id);
          }else { 
            
          }
         
      }
    

  }else { 
    alert('This is your newest added household!');
  }
  }
    return;

  }

  const assignUser = async(user_id) => { 
    let id = user_id.value;
    const response = await fetch(`${api}/api/household/assign?user_id=${id}&household_id=${currentHouseholdId}`, { 
      method: "POST",
      headers: { 
        Authorization : jwtToken
      }
    })
  
    if(!response.ok){ 
      alert("Error assigning user");
     
    }else { 
      window.location.reload();
    }
    
  }

    
  
    const slideLeft = () => {
     
      setSlide('slide-left');
    };

    const slideRight = () => { 
      setSlide('slide-right');
    }
  


    const currentCustomer = [
      "New",
      "Quoted",
      "Quoted Hot",
      "Called, No Contact",
      "Called, LVM",
      "BAD NUMBER",
      "Do Not Call",
      "Not Elegible",
      "Price is too High",
      "Cancelled",
      "Terminated"
    ];

    const prospect = [
      "New",
      "Quoted",
      "Quoted Hot",
      "Called, No Contact",
      "Called, LVM",
      "BAD NUMBER",
      "Do Not Call",
      "Not Elegible",
      "Price is too High", 
      "Contacted  Quoted & Call Back",
      "Not Interested",
      "Try again in 6 months",
      "Followed Up",

    ];

    const formerCustomer = [ 
      "New",
      "Cancelled", 
      "Terminated"
    ]

    const updateStatusDescription = async(desc) => {
      
        let status_desc = desc.value; 
        //If these criterias are met the agents must submit a quote to the notes.
        if(status_desc === "Quoted" || status_desc === "Quoted Hot" || status_desc === "Price is too High" ){
          

          const response = await fetch(`${api}/api/household/status?household_id=${currentHouseholdId}`, { 
            method: "POST",
            body : JSON.stringify({ 'status_description': status_desc }),
            headers : { 
              'Content-Type': 'application/json',
            }
          })
          if(!response.ok){ 
            alert("Error updating status description");
          }else{ 
            //When the response comes back ok display the quotes form
            setCurrentPopup(5);
          }
          
        } else { 
          //For when they dont select one of the criterias to require quoting.
          const response = await fetch(`${api}/api/household/status?household_id=${currentHouseholdId}`, { 
            method: "POST",
            body : JSON.stringify({ 'status_description': status_desc }),
            headers : { 
              'Content-Type': 'application/json',
            }
          })
  
          if(!response.ok){ 
            alert("Error updating status description");
          }else { 
            window.location.reload();
          }
  

        }

        


        
    }
    
    const copyToClipboard = (text) => { 
      navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  
    }
    const handleOrgChange = async(e) => { 
    
      if(e.target.value === "add"){ 
       setParentHouseholdId(Cookies.get('currentHouseholdId'));
       return setCurrentPopup(12);
      }
      Cookies.set('currentHouseholdId', e.target.value); 
      return window.location.reload();


    
    }

    {/*const tileOptions = [
      "auto", "home", "jewelry", "health", "generalLiability", "workersCompensation", "businessOwnersPackage",
      "renters", "disability", "life", "umbrella", "event", "comprehensivePersonalLiability",
      "flood", "motor", "recreationalVehicles", "mobileHome", "landlords", "annuity",
      "fixedAnnuity", "variableAnnuity", "indexAnnuity", "excessLiability", "errorsAndOmissions",
      "directorsAndOfficers", "crime", "suretyBond", "miscellaneousBond", "packages", "property",
      "condominium", "inlandMarine", "installationBuildersRisk", "boatOwners", "offRoadVehicle",
      "smallCommercialPackage", "glass", "schedulePersonalProperty", "earthquake", "longTermCare",
      "nsf", "mexicanTravel", "roadside", "excessAndSurplus", "buildersRisk", "petHealth", "contract", "windstorm", "commercialAuto"
      ]; */}
      const tileOptions = [ 
        'Personal Auto',"Property", 'Commercial'
      ]



    const popups = [ 
      <HouseholdController setCurrentPopup = {setCurrentPopup}  />, //Household Controller
      <NewHouseHolds setActiveComponentIndex = {setActiveComponentIndex} setCurrentPopup = {setCurrentPopup}  api = {api} setLatestHousehold = {setLatestHousehold} parentHouseholdId={parentHouseholdId} setParentHouseholdId = {setParentHouseholdId}/>, //Adding new household
      <NewDrivers  setCurrentPopup={setCurrentPopup}  api = {api}  currentHouseholdId={currentHouseholdId} updateDrivers = {updateDrivers} getVehicles = {getVehicles}/>,  //Adding new driver
      <NewVehicles setCurrentPopup={setCurrentPopup} driverData = {driverData}  api = {api}  getVehicles = {getVehicles}/>, //Adding new vehicle. Passing driver data to display driver name when adding vehicle.
      <NewStatus api = {api} currentHouseholdId={currentHouseholdId}  setCurrentPopup={setCurrentPopup} householdData={householdData} currentCustomer = {currentCustomer} prospect = {prospect} formerCustomer = {formerCustomer}  />,
      <QuotePopup api = {api} setCurrentPopup={setCurrentPopup} currentHouseholdId={currentHouseholdId} />, 
      <EditDriver  setCurrentPopup={setCurrentPopup} driverData={driverData} api = {api} updateDrivers={updateDrivers} getVehicles = {getVehicles} />, 
      <EditVehicle setCurrentPopup={setCurrentPopup} driverData={driverData} api = {api} vehicleData = {vehicleData}  getVehicles = {getVehicles} />, 
      <NewTasks setCurrentPopup={setCurrentPopup} api = {api} getTasks={getTasks} />,
      <ChangeOrg setCurrentPopup={setCurrentPopup} />, 
      <HouseholdInfo setCurrentPopup={setCurrentPopup}  householdData = {householdData} getHouseholdData= {getHouseholdData}/>, 
      <SelectPrimaryDriver setCurrentPopup={setCurrentPopup} driverData={driverData} updateDrivers={updateDrivers}/>, 
      <NewOrganization setCurrentPopup = {setCurrentPopup}  api = {api} setLatestHousehold = {setLatestHousehold} parentHouseholdId={parentHouseholdId} setParentHouseholdId = {setParentHouseholdId} />
    ]
  
    /*Arr or props that will be passed into the different tile view*/
    const arrOfProps = [ 
      driverData, 
      null, 
      null
    ]

    const handlePropIndexSelect = (index) => { 
      switch(index){ 
        case 'Personal Auto': 
          dispatch(personal_auto());
          break;
        case 'Property': 
          dispatch(property());
          break;
        case 'Commercial': 
          dispatch(commercial())
          break;
        default: 
        dispatch(commercial());
      }
    }



  return (<>
  {householdData && currentUserData && ( 
      <div className="middle-section-top light-dark-container ">
        <div className=' text-align-left  align-items-center light-dark-container display-flex   '>
          <h4 className='light-dark-container ' id = "household-status">{householdData.status + ' '}    
            <select className=' background-color-full-dark  margin-top-5px new-household-input padding-10px' 
              id = "top-level-status-desc" 
              value = {householdData.status_description} 
              autocomplete="off" 
              onChange={() => updateStatusDescription(document.getElementById('top-level-status-desc'))}>
                { householdData && (householdData.status === 'Current Customer') ? 
                  currentCustomer.map(options => ( 
                    <option value = {options} >
                      {options}
                    </option>
                  ))
                : 
                householdData && (householdData.status === "Prospect") ?  
                  prospect.map( options => ( 
                    <option value = {options}>
                      {options}
                    </option>
                  )) 
                : 
                householdData && (householdData.status.toLowerCase() === "former customer") ?
                  formerCustomer.map( options => ( 
                    <option value = {options}>
                      {options}
                    </option>
                  ))
                : null
                }
              </select>
            </h4>
     
  {householdData && ( 
         householdData && currentUserData[0]?.role === "Admin" || currentUserData[0]?.role === "Supervisor" ?  
         <h4 className=' hover-red light-dark-container' title ="Assigned Already" >Assigned To  
          <select className='background-color-full-dark padding-10px margin-top-5px new-household-input'
            id = "top-level-assign-user" 
            onChange={() => assignUser(document.getElementById('top-level-assign-user'))}>
            <option value="" selected disabled>
              {allUserData && householdData.user_id ? allUserData.find(user => user.id === householdData.user_id)?.full_name : "Unassigned"}
             </option>
            {allUserData && allUserData.map(user => (<option value = {user.id || "N/A"}>{user.full_name}</option>))}   
          </select>
         </h4> 
   : null )}
        <select className='background-color-full-dark padding-10px margin-top-5px new-household-input' onClick={(e)=> handlePropIndexSelect(e.target.value)}>
         {tileOptions.map((option, index) => ( 
          <option value = {option}>{option}</option>
         ))}
        </select>
         <button type = "button " className='green-submit-btn  ' onClick = {() => setCurrentPopup(8)}>New Task</button>
         <div className='padding-left-5px light-dark-background'> 
            <span class = "light-dark-background ">Created at: {householdData?.date_created && new Date(householdData.date_created).toLocaleTimeString() + " "  + new Date(householdData.date_created).toLocaleDateString() }</span>
         </div>
          
         </div>
         <div className='light-dark-background text-align-left padding-left-10px '><button className = 'green-submit-btn'onClick={() => setToggleOrg((prevState) => !prevState)}>✔️</button>{toggleOrg === true ? (  
          <select className='background-color-full-dark padding-10px  new-household-input' onChange={handleOrgChange} > 
            <option default value = {Cookies.get('currentHouseholdId')}>Select Organization</option>
            {householdData && householdData.organization_household_id  ? (<option value = {householdData.organization_household_id}>Parent Household</option>) : null}
            {householdData && householdData.organization_household_id === null ? (<option value = 'add'>Add Organization</option>): null} 
            {organizations && organizations.map(org => ( <option value = {org.household_id}>{org.organization ? org.organization : "No organization name specified"}</option>))}
          </select>
        ): null} {householdData.organization !== 'null'? <span className = "light-dark-background">{householdData.organization}</span>: null}</div>
      <TileDisplay slide= {slide} driverData = {arrOfProps[tileIndex]} setCurrentPopup = {setCurrentPopup} slideRight={slideRight} slideLeft={slideLeft} togglePopUpByEl={togglePopUpByEl} />
      </div>
          
)}






    
        {householdData ? (
          
            

      <div className="middle-section-middle light-dark-container margin-top-50">
        <h3 className="light-dark-container">Household Contact Information:</h3>
        <div className = 'light-dark-container gird-container-household-contact'>
          <label className="light-dark-container">
            <b className="light-dark-background color-orange cursor-pointer " title = "Click To Copy"  onClick={() => copyToClipboard(householdData.home_phone) }>Phone:</b> <a className= "light-dark-container" href={'tel:' + householdData.home_phone}>{formatPhoneNumber(householdData.home_phone)}</a>
          </label>
          <label className="light-dark-container">
            <span className="light-dark-background " title = "Click To Copy" onClick={() => copyToClipboard(householdData.email) }><><b className = "light-dark-background color-orange cursor-pointer">Email:</b> {householdData.email === 'null' ? null : householdData.email}</></span> 
          </label>
          <label className="light-dark-container">
          </label>
          <label className="light-dark-container">
            <span className="light-dark-background " title = "Click To Copy" onClick={() => copyToClipboard(householdData.status_description) }>{householdData.status_description !== 'Policy In Force' && householdData.status !== 'Terminated'  ? null :(<><b className = "light-dark-background color-orange cursor-pointer"></b> {householdData.status_description}</>)}</span> 
          </label>
          <label className="light-dark-container">
            <b className="light-dark-background color-orange cursor-pointer" title = "Click To Copy"  onClick={() => copyToClipboard(householdData.address_line_one) }>Street:</b>{' '}
            {householdData.address_line_one}
          </label>
          <label className='light-dark-container'> 
            <b className="light-dark-background color-orange cursor-pointer" title = "Click To Copy"  onClick={() => copyToClipboard(householdData.city) }>City:</b>{' '}
              {householdData.city}
          </label>
          <label className='light-dark-container'> 
            <b className="light-dark-background color-orange cursor-pointer" title = "Click To Copy"  onClick={() => copyToClipboard(householdData.state) }>State:</b>{' '}
              {householdData.state}
          </label>
          <label className="light-dark-container">
            <b className="light-dark-background color-orange cursor-pointer" onClick={() => copyToClipboard(householdData.zip_code) } title = "Click To Copy">Zip Code:</b> {householdData.zip_code}
          </label>
        </div>
        </div>
    ) : (
      
      <div className = "flex-center-column light-dark-container"><img   className = "light-dark-container ten-percent-width" src = "https://i.gifer.com/ZKZg.gif"/></div>
    
    )}
       <div className='light-dark-container .flex-across household-control-btns '>
            <button type="button" className=" green-submit-btn" id="previous-household" onClick = {changeHousehold} >Previous Household</button>
            {currentUserData && currentUserData[0]?.id == 2 ?   <label className='light-dark-container margin-right-20px'>  ID: {householdData?.household_id}</label> : null}
          <button type="button" className=" green-submit-btn" id="next-household" onClick = { changeHousehold} >Next Household</button>
          </div>
      
      
      <div className="middle-section-middle light-dark-container margin-top-50" >
        <TabSection  vehicleData = {vehicleData}  currentPopup = {currentPopup} setCurrentPopup = {setCurrentPopup} api ={api} activeComponentIndex ={activeComponentIndex} setActiveComponentIndex ={setActiveComponentIndex} currentUserData={currentUserData}  userIdAndFullName = {userIdAndFullName}/>
      </div>
     
   
     { popups && popups[currentPopup]}
  




       
      


      
    </>
  );
}

export default MiddleSide;

import React, {useRef, useState, useEffect} from 'react'


function AddDocumentPopup({api, fetchDocs, policies, currentHouseholdId, setCurrentPopup,fetchData}) {
  const fileInput = useRef(null);
  const policyNum = useRef(null)
  const [fileNames, setFileNames] = useState([])
  useEffect(() =>{ 
    const fetch = async() => { 
      await fetchData();
    }
    fetch();
  } , [])
  const uploadDocument = async(files) => { 
    const policyDoc = fileInput.current.files;
    const formData = new FormData();

    const policyNumber = document.getElementById('policy-number-input').value;

    if (files?.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append('policyDocs', files[i]);
      }
    }


    if (policyDoc.length > 0) {
      // Append each file to FormData
      for (let i = 0; i < policyDoc.length; i++) {
        formData.append('policyDocs', policyDoc[i]); // Note the array-like naming
      }
    }
          formData.append('household_id', currentHouseholdId);
          formData.append('policy_number', policyNumber);


      

      
      const response = await fetch(`${api}/api/upload_documents`, {
          method: 'POST',
          body: formData,
          
      })
      if(!response.ok){ 
        alert('Error uploading file');
        return;
      }else{ 
        
       fetchDocs();
       fetchDocs();
       setCurrentPopup(null)

      }  
    
    
  
  }

  const appendToDropZone = () => { 
    
    fileInput.current.click();
      fileInput.current.addEventListener('change', () => { 
        const files  = fileInput.current.files; 
        const fileNames = Array.from(files).map((file) =>  file.name)
        setFileNames(fileNames);
      }, { once: true })
  }

  const handleDragOver = (event) => {
    event.preventDefault(); // Necessary to allow dropping
  }

  const handleDrop = async(event) => {
    event.preventDefault();
 
    const files = event.dataTransfer.files;
    const newFileNames = Array.from(files).map(file => file.name);
    setFileNames(prevItems => [...prevItems, ...newFileNames]);
    await uploadDocument(files);
  } 

  
  return (
    <> 
   
    <div className='background-color-full-dark border-radius-10px popup' id = "add-document-popup" >
    <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick = {() => setCurrentPopup(null)}>❌</button></div>
      <div className='flex-container-el padding-20px'> 
      <h3 className='text-align-left '>Add New Document</h3>
      <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px ' id ="policy-id-input" >Policy Number*</label>
      <select className='background-color-full-dark regularTextInput margin-top-5px  input max-width '   id = "policy-number-input" ref = {policyNum}>
        {policies &&  policies.map( policy => (
          <option value = {policy.policy_id}>{policy.policy_id}</option>
        ))}
      </select> 
      <div id = "drop_zone" className='width-400px' onClick = {() => {appendToDropZone()}} onDrop={handleDrop}
      onDragOver={handleDragOver} >
      {fileNames.length > 0 ?  fileNames.map(fileName => (<p id = "file_name"> {fileName}</p> )): <p id = "file_name" className = 'text-align-center'> Drag or Click!  <br/> CTRL + Right Click To Select Multiple</p> }
        <input type = 'file' className='background-color-full-dark margin-top-5px request-type input max-width file-input  ' id = "document_upload" ref = {fileInput} autocomplete="off" multiple/>
      </div>
      <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " id = "add=driver-submit-btn" onClick = {() => uploadDocument()} >Add new documents</button>
      </div>
    </div>
    </>
  )
}

export default AddDocumentPopup







 import {React, useRef} from 'react'
 
 function DeletedocumentPopup({documentData, setToggleDoc, api, fetchDocs, popups, currentDocID  }) {
    const document = useRef();

    const DELETE_DOCS_REQ = async(value) => { 
        
        const response = await fetch (`${api}/api/delete_documents`, { 
            method : "POST",
            body: JSON.stringify({ value }),
            headers: {
                'Content-Type': 'application/json' 
            }
        })
         return response; 

    }



    const deleteDocs = async() => { 
        const response = await DELETE_DOCS_REQ(currentDocID);
            if(response.stautus === 500){ 
                return alert('Cannot Delete! Check connection!')
            }else { 
                fetchDocs();
                setToggleDoc(null);
            }
       

    }


   return (
    <div className='background-color-full-dark border-radius-10px popup' >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick = {() => setToggleDoc(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
            <h3 className='text-align-left margin-bottom-10'>Delete Document</h3>

            <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " onClick= {() => deleteDocs()} >Delete Document</button>
            </div>
   </div>
   )
 }
 
 export default DeletedocumentPopup
 



import React from 'react'

function OldLeads({lead, i , acceptLead, index, dateOptions}) {
  return (
   
    <div className='lead-card   text-align-center  light-dark-container grid-child '>
    
      
          <div className="flex-column-no-ho-centered light-dark-container text-align-center ">
          {index === 0 ?  <h3 className=' light-dark-container '>Old Leads</h3> : null}
            <label className={index === 2 ? 'light-dark-container ': 'light-dark-container blur '}><b className='light-dark-container'>Name:</b><span className={index === 2 ? 'light-dark-container ': 'light-dark-container blur'}>{lead?.firstname + " " + lead?.lastname} </span> </label>
            <label className={index === 2 ? 'light-dark-container ': 'light-dark-container blur'}><b className='light-dark-container'>Date created:</b><span className={index === 2 ? 'light-dark-container ': 'light-dark-container blur'}>{new Date(lead?.date_created[0]).toLocaleString('en-US', dateOptions)} </span></label> 

            <label className='light-dark-container'><b className='light-dark-container'>Phone Number:</b> {lead.home_phone}</label>
            <button type="button" className='button-default green-submit-btn margin-top-10px max-width' onClick={() => acceptLead(lead?.household_id[0])}>Accept Lead</button>
          </div>
        
    </div>
  )
}

export default OldLeads

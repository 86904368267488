import {React, useEffect, useState} from 'react'
import Cookies from 'js-cookie';
import EditDocumentPopup from './documents_popups/EditDocumentPopup';
import DeletedocumentPopup from './documents_popups/DeletedocumentPopup';


function Documents({togglePopUpById, api, documentData,  fetchDocs, currentUserData, setCurrentPopup, policies }) {
    const [toggleDoc, setToggleDoc] = useState(null);
    const [currentDocID, setCurrentDocID] = useState();
    useEffect(() => {
       
       fetchDocs();
       
    }, []); 

   
    

    const downloadFile = async (blobName) => {
        try {
          const response = await fetch(`${api}/api/download_documents`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ blobName }),
          });
      
          if (!response.ok) {
            throw new Error('Failed to download file');
          }
      
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = blobName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
    }
    const arr = [
      <EditDocumentPopup currentDocID = {currentDocID} api = {api} fetchDocs= {fetchDocs} setToggleDoc={setToggleDoc} />, 
      <DeletedocumentPopup api = {api}  documentData = {documentData} setToggleDoc={setToggleDoc} fetchDocs = {fetchDocs} currentDocID = {currentDocID}  />
    ]

  return (
    <>
    {arr[toggleDoc]}
    <div className='overflow-wrapper'>
    <table className='max-width margin-top-20px ' id ="documents_table">
            <thead>
                <tr >
                    <th>Policy </th>
                    <th>Document</th>
                    <th>Description</th>
                    <th>Uploaded</th>
                    <th>Action</th>
                </tr>
            </thead>
    
            <tbody   id = "document_tab_table_body" > 
            
    {documentData ? 
    documentData.map(doc => ( 
                <tr key ={doc.uw_action_documents_id}>
                <td > 
                {doc.policy_number}
                </td>
                <td > 
                {doc.document_type}
                </td>
                <td> 
                {doc.document_desc}
                </td>
                <td> 
                <u className = "hover-cursor-pointer"onClick={() => downloadFile(doc.document_name)}>{doc.document_name}</u>
                <br></br>
                {new Date(doc.created_on).toLocaleDateString('en-US')}
                </td>
                <td> 
                <button type = "button" className='button-default-dark-background' onClick={() =>{ setToggleDoc(0); setCurrentDocID(doc.document_name)}} >✍️</button><button type = "button" className='button-default-dark-background' onClick={() => window.open('mailto:name@gmail.com')}>📧</button>{currentUserData && (currentUserData[0]?.role === "Admin" ||currentUserData[0]?.role === "Supervisor" ? <button type = "button" className='button-default-dark-background' onClick={() => {setToggleDoc(1); setCurrentDocID(doc.document_name)}}>🗑️</button> : null )}
                </td>
                </tr>
            )): null}      

              
            </tbody>
           
        
            </table>
         
            </div>


        {/*Edit document popup
        <div className='background-color-full-dark border-radius-10px popup' id = "edit-document" hidden>
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => togglePopUpById("edit-document")} >❌</button></div>
        <div className='flex-container-el padding-20px'> 
        <h3 className='text-align-left margin-bottom-10'>Edit Document</h3>
        <select className='max-width' id = "selectionValueEdit"> 
        {documentData ? documentData.map(document => ( 
                <option value = {document.document_name}>{document.document_name}</option>
            )): null}
        </select>
        <label for = "lastname" className='background-color-full-dark text-align-left   margin-top-5px '>Document Description</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width  ' id = "document_description" autocomplete="off"/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Document type</label>
        <select  className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width  ' id = "document_type" autocomplete="off">
          <option value = "Additional Docs">Additional Docs</option>
          <option value = "Endorsements">Endorsements</option>
          <option value = "Proof Of Home Ownerhsip">Proof Of Home Ownership</option>
          <option value = "Drivers License">Drivers License</option>
          <option value = "FL DL Check">Fl Dl Check</option>
        </select>

        <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " onClick = {()=> editDocs(document.getElementById('selectionValueEdit'), document.getElementById('document_description'), document.getElementById('document_type'))} >Edit Document</button>
        </div>
      </div>
 */}
      


    </>
  )
}

export default Documents
